<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {
      'Crypto Withdrawals': 'Saída de moedas',
      'Transfer currency': 'Transferir moedas',
      'Amount': 'Quantidade',
      'Destination wallet will receive': 'Carteira de destino receberá',
      'Identifier': 'Identificador',
      'Destination wallet': 'Carteira do destino',
      'Destination is required': 'Destino é obrigatório',
      'Amount is required': 'Quantidade é obrigatório',
      'Currency is required': 'Moeda é obrigatório',
      'Currency': 'Moeda',
      'Sent in': 'Enviado em',
      'Transaction': 'Transação',
      'Value': 'Valor',
      'Fee': 'Taxa',
      'Use balance': 'Usar saldo',
      'Receivable': 'A receber',
      'Status': 'Estado',
      'Transfer': 'Transferir',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',

      'pending': 'pendente',
      'executed': 'executado',
      'canceled': 'cancelado',
    },
    es: {
      'Crypto Withdrawals': 'Saída de moedas',
      'Transfer currency': 'Transferir moedas',
      'Amount': 'Quantidade',
      'Destination wallet will receive': 'Carteira de destino receberá',
      'Destination wallet': 'Carteira de destino',
      'Destination is required': 'Destino é obrigatório',
      'Amount is required': 'Quantidade é obrigatório',
      'Currency is required': 'Moeda é obrigatório',
      'Identifier': 'Identificador',
      'Currency': 'Moeda',
      'Sent in': 'Enviado em',
      'Transaction': 'Transação',
      'Value': 'Valor',
      'Fee': 'Taxa',
      'Use balance': 'Usar saldo',
      'Receivable': 'A receber',
      'Status': 'Estado',
      'Transfer': 'Transferir',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',

      'pending': 'pendente',
      'executed': 'executado',
      'canceled': 'cancelado',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      filter: {
        currency: {
          value: 'omc',
          options: {
            omc: 'OMC',
          }
        },
        status: {
          value: 'all',
          options: {
            all: 'Todos',
            pending: 'Pendentes',
            confirmed: 'Confirmado',
            canceled: 'Cancelado',
          }
        }
      },

      page: 1,
      pages: 0,

      table: {
        heade: [
          'Identifier', 'Currency', 'Sent in', 'Transaction', 'Value', 'Fee', 'Receivable' ,'Status'
        ],
        body: [],
        loading: true,
        errored: false,
        empty: false,
      },

      transfer: {
        modal: false,
        loading: false,
        currency: 'omc',
        wallet: null,
        amount: null,
      },

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 8
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    transfer: {
      currency: { required },
      wallet: { required },
      amount: { required },
    }
  },
  methods: {
    getWithdrawals() {
      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('exchange/crypto/withdrawals?page=' + this.page + '&currency=' + this.filter.currency.value + '&status=' + this.filter.status.value)
        .then((response) => {
          if (response.data.status == 'success') {
            this.pages = response.data.pages
            this.table.body = response.data.list

            this.table.empty = false
            this.table.loading = false
          } else {
            this.table.empty = true
            this.table.loading = false
          }
        })
        .catch(error => {
          this.table.errored = error
          this.table.empty = false
          this.table.loading = false
        })
    },
    transferCurrency() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { currency, wallet, amount } = this.transfer;
        if (currency && wallet && amount) {
          this.transfer.loading = true
          api
            .post('exchange/crypto/withdrawals', {
              currency: this.transfer.currency,
              wallet: this.transfer.wallet,
              amount: this.transfer.amount,
            })
            .then(response => {
              if (response.data.status=='success') {
                this.transfer.currency = ''
                this.transfer.wallet = ''
                this.transfer.amount = ''
                this.$v.$reset()

                this.transfer.modal = false

                this.$noty.success(response.data.message)
              } else {
                this.$noty.error(response.data.message)
              }

              this.transfer.loading = false
            })
        }
      }
    },
  },
  mounted() {
    this.getWithdrawals()
  },
  watch: {
    page: function() {
      this.getWithdrawals();
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0">{{ t('Crypto Withdrawals') }}</h4>
          <button class="btn btn-success" @click="transfer.modal = true">{{ t('Transfer currency') }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex">
            <div class="mr-3">
              <b-input-group :prepend="t('Currency')">
                <b-form-select v-on:change="getWithdrawals()" v-model="filter.currency.value" :options="filter.currency.options"></b-form-select>
              </b-input-group>
            </div>
            <div class="mr-3">
              <b-input-group :prepend="t('Status')">
                <b-form-select v-on:change="getWithdrawals()" v-model="filter.status.value" :options="filter.status.options"></b-form-select>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td>{{ td.code }}</td>
                    <td class="text-uppercase">{{ td.currency }}</td>
                    <td>{{ td.create_date }}</td>
                    <td class="d-flex">
                      <span class="align-self-center text-truncate text-primary pr-3">{{ td.hash }}</span>
                      <button class="btn btn-primary btn-sm" v-clipboard:copy="td.hash">{{ t('Copy') }}</button>
                    </td>
                    <td>{{ td.value }}</td>
                    <td>{{ td.tax }}</td>
                    <td>{{ td.receiving }}</td>
                    <td class="text-uppercase">{{ t(td.state) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

     <paginate
      v-if="!table.errored && !table.empty && pages > 1"
      v-model="page"
      :page-count="pages"
      :page-range="3"
      :margin-pages="2"
      prev-text="<i class='mdi mdi-chevron-left'></i>"
      next-text="<i class='mdi mdi-chevron-right'></i>"
      :container-class="'pagination pagination-rounded justify-content-center mt-4'"
      :page-class="'page-item'"
      :page-link-class="'page-link'"
      :prev-link-class="'page-link'"
      :next-link-class="'page-link'"
      :break-view-link-class="'page-link'">
    </paginate>


    <b-modal v-model="transfer.modal" v-bind:hide-footer="true" :title="t('Transfer currency')" centered>
      <b-form @submit.prevent="transferCurrency">
        <b-form-group id="name-group" :label="t('Currency')" label-for="currency">
          <select v-model="transfer.currency" id="currency" :class="{ 'is-invalid': $v.transfer.currency.$error }" class="custom-select mb-1">
            <option v-for="(option,index) in filter.currency.options" :value="index" :key="index" >{{ option }}</option>
          </select>
          <div v-if="!$v.transfer.currency.required" class="invalid-feedback">{{ t('Currency is required') }}</div>
        </b-form-group>
        <b-form-group id="name-group" :label="t('Destination wallet')" label-for="wallet">
          <b-input-group>
            <b-form-input v-model="transfer.wallet" id="wallet" :class="{ 'is-invalid': $v.transfer.wallet.$error }" autocomplete="off"></b-form-input>
            <div v-if="!$v.transfer.wallet.required" class="invalid-feedback">{{ t('Destination is required') }}</div>
          </b-input-group>
        </b-form-group>
        <b-form-group id="amount" :label="t('Amount')" label-for="value">
          <b-input-group>
            <b-form-input id="amount" v-model="transfer.amount" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.transfer.amount.$error }" autocomplete="off"></b-form-input>
            <!-- <b-input-group-append>
              <b-button variant="primary">{{ t('Use balance') }}</b-button>
            </b-input-group-append> -->
          </b-input-group>
          <div v-if="!$v.transfer.amount.required" class="invalid-feedback">{{ t('Amount is required') }}</div>
        </b-form-group>
        <div class="mt-4 mb-3">
          <b-button :disabled="transfer.loading == true" type="submit" variant="default" class="btn-lg btn-block">
            {{ t('Transfer') }}
            <b-spinner v-if="transfer.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.text-truncate {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
  text-overflow: ellipsis;
  width: calc(100vw * 0.2);
}
</style>